import React from 'react';
import styled from 'styled-components';

import { observer } from 'mobx-react';
import UserStore from '../store/User';
import store from 'src/store';

import { Avatar, Button } from 'src/libs';
import { useTranslation } from 'src/i18n/useTranslation';
import { device } from 'src/theme';

export default observer(function UserProfile({
    visible,
    user,
    onLogout
}: {
    visible: boolean,
    user: UserStore,
    onLogout(): void;
}) {
    const { t } = useTranslation();

    if (visible) {
        return (
            <Container>
                <Profile>
                    <Avatar size={store.device?.isMobile ? 24 : 48} image={user.avatarUrl} />
                    <span>{user.firstName}</span>
                </Profile>
                
                {!store.device?.isMobile && 
                    <Button
                        size="small"
                        color="transparent"
                        bordered={true}
                        style={{ color: 'var(--accent-red)', boxShadow: '0 0 0 1px rgba(239, 83, 80, 0.2)' }}
                        onClick={onLogout}
                    >{t("chronos.app.components.appSwitcher.signOut")}</Button>
                }
            </Container>
        )
    } else {
        return null
    }
})

const Container = styled.figure`
    display: flex;
    position: relative;
    padding-top: 4rem;
    padding-bottom: 1.5rem;
    justify-content: space-between;
    align-items: center;
    color: var(--text-primary);

    @media ${device.mobileL} {
        padding-top: 5.5rem;
        padding-bottom: 1rem;
    }
`
const Profile = styled.div`
    display: flex;
    position: relative;
    align-items: center;

    & span + span {
        margin-left: 1rem;
        /* font-weight: 500; */
        font-size: 2.25rem;
        line-height: 1;

        @media ${device.mobileL} {
            font-weight: 400;
            font-size: 1.5rem;
        }
    }
`