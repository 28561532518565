import React from 'react';
import styled, { css } from 'styled-components';

import Spinner from 'src/components/ui/Spinner';
import { ReactComponent as  CloseIcon } from 'src/static/icons/system/close.svg';
import { device } from 'src/theme';

export default function PromtContainer({
  icon,
  title,
  subtitle,
  content,
  buttons,
  footer,
  preloading = false,
  onClose
}: {
  icon?: React.ReactNode
  title?: string | React.ReactNode
  subtitle?: string | React.ReactNode
  content: string | React.ReactNode
  buttons: React.ReactNode | React.ReactNodeArray
  footer?: React.ReactNode
  preloading?: boolean
  onClose(): void
}) {
  return (
    <PromtWrapContainer className='prompt-container'>
      <Header preloading={preloading} className='prompt-header'>
        { Boolean(icon) && <IconWrapper>{icon}</IconWrapper> }
        <CloseWrapper onClick={onClose} preloading={preloading}>
          <CloseIcon />
        </CloseWrapper>
      </Header>
      {Boolean(title) && <Title preloading={preloading} className='prompt-title'>{title}</Title>}
      {Boolean(subtitle) && <Subtitle preloading={preloading} className='prompt-subtitle'>{subtitle}</Subtitle>}
      <Content preloading={preloading} className='prompt-content'>{content}</Content>
      <Buttons preloading={preloading} className='prompt-buttons'>{buttons}</Buttons>
      {footer && <Footer preloading={preloading} className='prompt-footer'>{footer}</Footer> }

      {preloading && <Preloader><Spinner/></Preloader>}
    </PromtWrapContainer>
  )
}


const PromtWrapContainer = styled.div`
  position: relative;
  padding: 2.25rem;
  width: 31.5rem;
  min-height: 29.062rem;

  border-radius: 0.5rem;
  background-color: var(--workspace-widget-background);

  @media ${device.mobileL} {
    max-width: initial;
    max-height: 100vh;
    width: 94vw;
    height: auto;
    margin: 0 auto;
  }
`
const Header = styled.header<{ preloading: boolean }>`
  display: flex;
  position: relative;
  justify-content: space-between;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
  }

  ${props => props.preloading && css`
    pointer-events: none;
    opacity: 0.2;
  `}
`

const IconWrapper = styled.div`
  position: relative;
  padding: 0.562rem;
  border-radius: 0.312rem;
  background-color: var(--element);
  color: #fff;
`
const CloseWrapper = styled.div<{ preloading: boolean }>`
  position: relative;
  margin-left: auto;
  padding: 0.562rem;
  cursor: pointer;
  color: var(--text-third);
  /* transition: color ease 0.1s; */

  &:hover {
    color: var(--text-primary);
  }

  ${props => props.preloading && css`
    cursor: default;
    pointer-events: none;
  `}

`

const Title = styled.h1<{ preloading: boolean }>`
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 1.687rem;

  ${props => props.preloading && css`
    pointer-events: none;
    opacity: 0.2;
  `}
`
const Subtitle = styled.p<{ preloading: boolean }>`
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: var(--text-secondary);

  ${props => props.preloading && css`
    pointer-events: none;
    opacity: 0;
  `}
`

const Content = styled.div<{ preloading: boolean }>`
  position: relative;
  margin-top: 1.812rem;
  
  label {
    display: inline-block;
    position: relative;
    margin-bottom: 0.437rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
  }

  ${props => props.preloading && css`
    pointer-events: none;
    opacity: 0;
  `}
`

const Buttons = styled.div<{ preloading: boolean }>`
  position: relative;
  margin-top: 1.875rem;

  ${props => props.preloading && css`
    pointer-events: none;
    opacity: 0;
  `}
`

const Footer = styled.footer<{ preloading: boolean }>`
  position: relative;
  margin-top: 1.5rem;

  ${props => props.preloading && css`
    pointer-events: none;
    opacity: 0.2;
  `}
`

const Preloader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
`