import React from 'react';
import styled from 'styled-components';
import { Notifications } from 'src/libs';
import { device } from 'src/theme';

export default function PageContainer({
  children,
  loading
}: {
  children: React.ReactNode | Array<React.ReactNode>;
  loading?: boolean;
}) {
  return (
    <Container className={`page-container${loading ? ' loading' : ''}`}>
      {children}
      <Notifications/>
    </Container>
  )
}

export const Container = styled.div`
  position: relative;
  max-width: var(--workarea-width);
  margin: 0 auto;
  margin-top: 3.375rem;
  margin-bottom: 3.25rem;
  
  @media only screen and (max-device-width: 480px) {
    padding: 0 1rem;
  }

  &:before {
    display: none;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: var(--workspace-background);
    opacity: 1;
    z-index: 777;
    transition: opacity 0.2s ease 0.1s;
  }

  &:after {
    display: none;
    position: absolute;
  }

  &.loading {
    
    &:before {
      display: block;
      opacity: 0.8;
    }

    &:after {
      display: block;
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      width: 3em;
      height: 3em;
      margin-top: -1.5rem;
      margin-left: -1.5rem;
      font-size: 0.625rem;
      text-indent: -9999em;
      border-top: 0.3em solid rgba(255, 255, 255, 0.2);
      border-right: 0.3em solid rgba(255, 255, 255, 0.2);
      border-bottom: 0.3em solid rgba(255, 255, 255, 0.2);
      border-left: 0.3em solid #ffffff;
      border-radius: 50%;

      transform: translateZ(0);
      animation: load 1.1s infinite linear;

      z-index: 778;
      @keyframes load {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  h1 {
    margin-bottom: 0.75rem;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  h2 {
    margin-bottom: 0.437rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.187rem;
  }

  h3 {
    margin-bottom: 0.75rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--text-secondary);
  }

  p {
    font-weight: normal;
    color: var(--text-secondary);

    a, ins {
      text-decoration: none;
      color: var(--accent-blue);
    }
  }

  h1 + p {
    font-size: 1rem;
    line-height: 1.5rem;
    max-width: 70%;
  }

  h2 + p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  & section {
    position: relative;
    margin-top: 2rem;
  }

  @media ${device.mobileL} {
    margin-top: 1.375rem;
  }
`