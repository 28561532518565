import React from 'react';
import styled from 'styled-components';

export default function InnerContainer({
  children
}: {
  children: React.ReactNode | Array<React.ReactNode>
}) {
  return (
    <InnerContainerComponent className='inner-container'>{children}</InnerContainerComponent>
  )
}

export const InnerContainerComponent = styled.div`
  position: relative;
  max-width: var(--workarea-width);
  margin: 0 auto;

  @media only screen and (max-device-width: 480px) {
    padding: 0 1rem;
  }
`