import "./common.css";
import "./light.css";

export default function setTheme(theme: "dark" | "light") {
  document.documentElement.setAttribute("data-theme", theme);
}

/* Media queries  */
const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '480px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`
};

export const orientation = {
  landscape: `(orientation: landscape)`,
  portrait: `(orientation: portrait)`,
};