import React from 'react';
import PromtContainer from 'src/components/containers/PromptContainer';
import SecurityForm from 'src/pages/Security/SecurityForm';
import { Input, Button, InputContainer, show } from 'src/libs';
import Steps from 'src/components/Steps';

import { ReactComponent as KeyIcon } from 'src/static/icons/system/key.svg';

import api from 'src/services/api';
import { isSecureSession, isOAuthUser } from 'src/utils';

import { observer } from 'mobx-react';

import store from 'src/store';
import { useTranslation } from 'src/i18n/useTranslation';


export default observer(function PasswordConfirmForm({
  onClose
}: {
  onClose(): void
}) {
  const [needLogin, setNeedLogin] = React.useState<boolean>(false)

  const passwordFormat = /(?=.*?[A-Z,a-z])(?=.*?[0-9])/
  const [isPasswordValid, setIsPasswordValid] = React.useState<boolean>(false)
  const [isEqual, setIsEqual] = React.useState<boolean>(false)
  const [confirmPassword, setConfirmPassword] = React.useState<string>('')

  React.useEffect(() => {
    if (!isSecureSession() && !isOAuthUser()) {
      setNeedLogin(true)
    }
  }, [])

  const onCloseHandler = () => {
    store.UserStore.mutablePassword = ''
    onClose()
  }
  const { t } = useTranslation();
  return ( 
    <>
    {
      needLogin ?
          <SecurityForm onLogin={() => {
            setNeedLogin(false)
          }} onClose={onCloseHandler}/>
      :
          <PromtContainer
            icon={<KeyIcon />}   
            title={t("chronos.auth.resetPassword.newPassword")}
            subtitle={t("chronos.profile.security.passwordConfirmForm.newPassword.description")}
            content={
              <>
                <InputContainer label={t("chronos.profile.security.passwordConfirmForm.PasswordPlaceholder")}>
                  <Input type="password" size="big" value={store.UserStore.mutablePassword} onChange={(val) => {
                    store.UserStore.mutablePassword = val
                    setIsPasswordValid(passwordFormat.test(val))
                    setIsEqual(val === confirmPassword)
                  }} />
                </InputContainer>

                <InputContainer label={t("chronos.profile.security.passwordConfirmForm.repeatPassword")}>
                  <Input type="password" size="big" value={confirmPassword} onChange={(val) => {
                    setConfirmPassword(val)
                    setIsEqual(store.UserStore.mutablePassword === val)
                  }} />
                </InputContainer>
              </>
            }
            footer={<Steps step={1} />}
            onClose={onCloseHandler}
            buttons={<Button disabled={!isPasswordValid || !isEqual} size="medium" color="#4093F4" onClick={() => {
              api.changePassword(store.UserStore.mutablePassword, confirmPassword)
                .then((res) => {
                  onCloseHandler();
                  show({ text: t("chronos.profile.security.passwordConfirmForm.passwordChanged.success"), type: 'success', timeout: 5000 });
                })
                .catch((res) => {
                  console.log('relogin err -', res);
                  switch (res.message) {
                    case 'WRONG CHANNEL': {
                      show({ text: t("chronos.profile.security.passwordConfirmForm.passwordChanged.error"), type: 'error', timeout: 5000 });
                      break;
                    }
                    default: {
                      show({ text: t("chronos.profile.showMessage.somethingWentWrongContactSupport"), type: 'error', timeout: 5000 });
                        store.sendReport('relogin res -', res);
                    }
                  }
                })
            }}>{t("chronos.profile.security.passwordConfirmForm.changePassword")}</Button>}
          />
    }
    </>
  )
})  
