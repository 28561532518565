const st = localStorage
export default {
  set(key: string, val: string) {
    return st.setItem(key, val)
  },
  get(key: string) {
    return st.getItem(key)
  },
  remove(key: string) {
    return st.removeItem(key)
  }
}