import React from 'react';
import styled from 'styled-components';
import { ReactMemo } from 'src/hooks';

import PromtContainer from 'src/components/containers/PromptContainer';
import SecurityForm from './SecurityForm';
import Steps from 'src/components/Steps';
import { Button, show } from 'src/libs';

import { ReactComponent as KeyIcon } from 'src/static/icons/system/key.svg'

import api from 'src/services/api'
import store from 'src/store'

import { isSecureSession, isOAuthUser } from 'src/utils'
import { useTranslation } from 'src/i18n/useTranslation';
import ReCAPTCHA from 'react-google-recaptcha';
import { isTester } from '../../helpers/autotests';

export default ReactMemo(function EmailConfirmForm({
  onClose
}: {
    onClose():void
}) {

  const [code, setCode] = React.useState<string>('')
  const [needLogin, setNeedLogin] = React.useState<boolean>(false)
  const [preloading, setPreloading] = React.useState<boolean>(false)
  const captchaRef = React.useRef<ReCAPTCHA>(null)
  const { t } = useTranslation();

  async function verifyEmail() {
    try {
      const token = await captchaRef.current?.executeAsync()

      if (!isTester(store.UserStore.email) && !token) {
        const message = 'Captcha error: ReCAPTCHA hasn\'t generated token. Probably user is bot.'
        throw new Error(message)
      }

      await api.verifyChannel(store.UserStore.mutableEmail, token!)
        .then((result) => {
          console.log('verify email result -', result)
          store.isChannelVerified = true
          setPreloading(false)
        })
        .catch((result) => {
          console.log('verify email err -', result)
          let message = t("chronos.profile.emailConfirmForm.somethingWrong")
          if (result.message === 'WRONG CHANNEL')  {
            message = t("chronos.profile.emailConfirmForm.noValidEmail")
          } else if (result.message === 'exist') {
            message = t("chronos.profile.emailChanger.emailAllreadyUsed");
            store.setConfirmEmailModalVisible(false)
          }
          store.isChannelVerified = false
          show({ text: message, type: 'error', timeout: 7000 });
        })
    } catch(error: any) {
        console.error(error.message)
    }
  }

  React.useEffect(() => {
    if (!isSecureSession() && !isOAuthUser()) {
      setNeedLogin(true)
    } else if (!store.isChannelVerified) {
      verifyEmail()
    }
  }, [])

  return (
    <>
      { needLogin ?
        <SecurityForm onLogin={() => { setNeedLogin(false); verifyEmail() }} onClose={onClose} />
        : <PromtContainer
          preloading={preloading}
          icon={<KeyIcon />}
          title={t("chronos.profile.emailChanger.changeEmail")}
          subtitle={t("chronos.profile.emailConfirmForm.weSendYouOnEmailCode", { email: store.UserStore.mutableEmail })}
          content={
            <CodeInput placeholder="****" value={code} onChange={(evt) => {
              setCode((evt.target as HTMLInputElement).value)
            }} />
          }
          buttons={<Button disabled={!code} size="medium" color="accent" onClick={() => {
            const newEmail = store.UserStore.mutableEmail
            api.changeChannnel(newEmail, code)
              .then((result) => {
                store.UserStore.email = store.UserStore.mutableEmail
                store.UserStore.mutableEmail = ''
                store.setConfirmEmailModalVisible(false)
                show({ text: t("chronos.profile.emailConfirmForm.emailSuccessChanged"), type: 'success', timeout: 5000 });
              })
              .catch((error) => {
                let message = error.message;
                if (error.message === 'invalid token') {
                  message = t("chronos.profile.security.phoneConfirmForm.changePhoneNumber.show.error");
                }
                show({ text: message, type: 'error', timeout: 5000 });
              })
          }}>{t("chronos.profile.emailConfirmForm.sendCode")}</Button>}
          footer={<Steps step={2} />}
          onClose={onClose}
        />
      }

      {!isTester(store.UserStore.email) && <ReCAPTCHA
        sitekey="6LdqFGUnAAAAAFPjZAmWlI1FS7TQNxF6vIsf5JKR"
        size="invisible"
        ref={captchaRef}
      />}
    </>
  )
})

const CodeInput = styled.input`
  display: block;
  position: relative;
  width: 11.75rem;
  height: 3rem;
  padding-left: 1rem;
  border: 1px solid var(--element);
  border-radius: 5px;
  background-color: var(--input-background);
  color: var(--text-secondary);

  font-family: 'Apercu Pro', sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.25rem;
  letter-spacing: 0.187rem;

  &::placeholder {
    color: var(--text-secondary);
    transform: translateY(20%)
  }

  &:focus {
    outline: none;
  }
`
