import React from 'react'
import styled, { css, CSSProperties } from 'styled-components'

import { ReactComponent as CloseIcon } from 'src/static/icons/system/close-small.svg'

export default function ToolTip({
  title,
  content,
  style,
  onClose
}: {
  title?: string;
  content?: string;
  style?: CSSProperties;
  onClose(): void;
}) {
  function closeHandler(evt: any) {
    evt.stopPropagation()
    onClose?.()
  }
  return (
    <Container style={style}>
      <CloseIcon onClick={closeHandler} />
      {title && <h2>{title}</h2>}
      {content && <p>{content}</p>}
    </Container>
  )
}

const Container = styled.div`
  --offset: 4.375rem;

  display: block;
  position: absolute;
  padding: 1.75rem var(--offset) 1.562rem 1.562rem;
  background-color: var(--accent-blue);
  border-radius: 6px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0.075rem;
    left: 1rem;
    width: 2.187rem;
    height: 1.128rem;
    color: var(--text-primary);
    background-color: var(--accent-blue);
    border-radius: 3px;

    transform: rotate(45deg);
  }

  & > svg {
    position: absolute;
    top: 1.562rem;;
    right: calc(var(--offset) / 2);
    width: 1.5rem;
    height: 1.5rem;
    color: var(--icon-secondary);

    &:hover {
      color: var(--icon-third);
    }
  }

  & > h2 {
    display: block;
    min-width: 20rem;
    margin-bottom: 0.612rem;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.28rem;
  }

  & > p {
    font-size: 0.875rem;
    line-height: 1.128rem;
  }
`