import { makeAutoObservable } from "mobx";

export default class PaymentStore {

  subscriptions: Array<any>
  transactions: Array<any>

  constructor() {
    this.subscriptions = [
      { uid: '0', app: "chronos.app.title", tariff: "chronos.profile.payments.amareurTariff", card: 'Mastercard *2234', active: true },
      // { uid: '1', app: 'Прогнозы', tariff: 'Тариф обычный', card: 'Mastercard *3745', active: true }
    ]
    this.transactions = [
      { date: '12.12.2020', tariff: "chronos.profile.payments.app.amateur", card: '*7213', sum: 599 },
      { date: '12.12.2020', tariff: "chronos.profile.payments.app.amateur", card: '*7213', sum: 700 },
      { date: '12.12.2020', tariff: "chronos.profile.payments.app.amateur", card: '*7213', sum: 330 },
      { date: '12.12.2020', tariff: "chronos.profile.payments.app.amateur", card: '*7213', sum: 599 },
      { date: '12.12.2020', tariff: "chronos.profile.payments.app.amateur", card: '*7213', sum: 599 }
    ]

    makeAutoObservable(this)
  }

}