import React from 'react'
import styled, { css } from 'styled-components'

import { ReactMemo } from 'src/hooks'
import { useTranslation } from 'src/i18n/useTranslation';

export default ReactMemo(function Steps({
  step = 1
}: {
  step?: number
}) {
  const { t } = useTranslation();
  return (
    <StepBlock step={step}>
      <figcaption>{t("chronos.app.instruments.widgets.rectifications.settings.step")} {step}</figcaption>
    </StepBlock>
  )
})

const StepBlock = styled.figure<{ step: number }>`
  display: flex;
  position: relative;
  align-items: center;

  figcaption {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--text-secondary);
    order: 1;
  }

  &:before,
  &:after {
    display: block;
    content: '';
    height: 0.312rem;
    width: 0.312rem;
    border-radius: 1.562rem;
    background-color: var(--text-third);
    transition: all ease 0.2s;
  }

  &:before {
    order: 2;
    margin-left: 1.5rem;
    ${props => props.step === 1 && css`
      width: 1rem;
      background-color: var(--text-primary);
    `}
  }

  &:after {
    order: 3;
    margin-left: 0.5rem;
    ${props => props.step === 2 && css`
      width: 1rem;
      background-color: var(--text-primary);
      width: 1rem;
      background-color: var(--text-primary);
    `}
  }
`