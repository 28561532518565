import { makeAutoObservable } from "mobx";

export default class NotificationStore {
  mailing: boolean = false
  reminders: boolean = false
  push: boolean = false
  sms: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  swith(field: string) {
    (this as any)[field] = !(this as any)[field]
  }
}