import JWT from 'jsonwebtoken';
import { authToken } from 'src/services/api';

import i18n from "src/i18n/i18n";

import { S3Urls } from 'src/libs';

export const s3urls = new S3Urls();

export function searchParams() {
  return new URLSearchParams(window.location.search);
}

export function dateFormat(date: any, options?: { [key: string]: string }) {
  return new Intl.DateTimeFormat('ru-RU', options).format(new Date(date))
}

export function daysInMonth(month: number, year: number): number {
  if (month === 2) {
    return year % 400 === 0 || year % 4 === 0 && year % 100 !== 0 ? 29 : 28;
  }
  return 30 + [1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1][month - 1];
}

export function formatMessage(n: number, text_forms: string[]): string {
  n = Math.abs(n) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) { return text_forms[2] }
  if (n1 > 1 && n1 < 5) { return text_forms[1] }
  if (n1 === 1) { return text_forms[0] }
  return text_forms[2];
}

export function isOAuthUser(): boolean {
  const token = authToken.getToken();
  const jwtData = token ? JWT.decode(token) : {}
  return ['google', 'yandex', 'vk'].includes(jwtData.type);
}

export function isSecureSession(): boolean {
  const token = authToken.getToken();
  const jwtData = token ? JWT.decode(token) : {}
  return Boolean(jwtData.secure);
}

export function accessOnlyForRu() {
  return i18n.language === 'ru';
}

export const getDeviceDetect = () => {
  const userAgent = typeof window.navigator === 'undefined'
    ? ''
    : navigator.userAgent;

  const isMobile =
    /android|webos|iphone|ipod|blackberry|bb|playbook|iemobile|windows phone|kindle|silk|opera mini|wpdesktop/i
    .test(userAgent);

  // Workaround to detect ipads safari with turned on "request desctop version" (turned on by default)
  const isIPadDesktop = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;

  const isTablet = isIPadDesktop ||
    /Tablet|iPad|Nexus 7|Nexus 10|SM-(T|X)\d+|KFAPWI/i
    .test(userAgent);
  const isIPad = isIPadDesktop || (isTablet && /iPad/i.test(userAgent));

  return { isMobile, isTablet, isIPad };
}