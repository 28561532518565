import React from 'react'

import Notify from 'src/components/ui/wrappers/Notify'
import { Button } from 'src/libs'
import { ReactComponent as WarningIcon } from 'src/static/icons/system/warning.svg'
import store from 'src/store'
import api from 'src/services/api'
import { useTranslation } from 'src/i18n/useTranslation'

export default function CloseSessionAlert() {
  const singleSession = store.UserStore.removableSession
  const { t } = useTranslation();
  return (
    <Notify
      icon={<WarningIcon />}
      title={singleSession ? t("chronos.profile.closeSessionAlert.exitTheSession") : t("chronos.profile.closeSessionAlert.logOutOfAllSessions")}
      message={t("chronos.profile.closeSessionAlert.logOutOfAllSessions.message")}
      buttons={
        <>
          <Button size="medium" color="transparent" onClick={() => {
            singleSession && (store.UserStore.removableSession = null)
            store.setCloseSessionsModalVisible(false)
          }}>{t("base.cancel")}</Button>
          <Button size="medium" color="red" onClick={() => {
            if (singleSession) {
              api.closeSession(singleSession)
                .then((res) => {
                  store.UserStore.removableSession = null
                  store.UserStore.fetchUserSessions()
                  store.setCloseSessionsModalVisible(false)
                })
                .catch((e) => {
                  console.log('close single session -', e)
                })
            } else {
            api.closeAllSessions()
              .then((res) => {
                store.UserStore.fetchUserSessions()
                store.setCloseSessionsModalVisible(false)
              })
              .catch((e) => {
                console.log('close all sessions -', e)
              })
            }
          }}>{t("chronos.profile.closeSessionAlert.exit")}</Button>
        </>
      }
    />
  )
}
