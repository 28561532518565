import React from 'react';
import styled, { css } from 'styled-components';

import { ReactMemo } from 'src/hooks';

export default ReactMemo(function NavigationContainer({
  children
}: {
  children: React.ReactNode | Array<React.ReactNode>
}) {
  return (
    <Container className="nav-container" childLength={(children as Array<React.ReactNode>)?.length}>{children}</Container>
  )
})

const Container = styled.nav<{ childLength: number; }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;

  ${({ childLength }) => childLength < 4 && css`
    justify-content: flex-start;
    gap: 3rem;
  `}
  
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: var(--input-border);
  }

  & a {
    display: flex;
    position: relative;
    padding: 1.06rem 0;
    align-items: center;

    font-size: 0.875rem;
    line-height: 0.875rem;
    font-weight: 500;

    color: var(--text-secondary);
    border-bottom: 2px solid transparent;
    transition: all ease .2s;

    @media only screen and (max-device-width: 480px) {
      font-size: 0.675rem;
      padding: 1rem 0;
    }

    /* &:not(:first-child) {
      opacity: 0.3;
      pointer-events: none;
    } */

    & svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.687rem;
      opacity: 0.6;
      transition: opacity ease .2s;

      @media only screen and (max-device-width: 480px) {
        display: none;
      }
    }

    &.active {
      color: var(--text-primary);
      border-color: var(--accent-blue);
      svg {
        opacity: 0.7;
      }
    }
  }

  & .tooltip-item {
    & > a {
      opacity: 0.3;
      pointer-events: none;
    }
  }
`