import React from 'react';
import styled, { css } from 'styled-components';

export default function Header({
  blurred,
  children
}: {
  blurred?: boolean;
  children: React.ReactNode
}) {

  return (
    <HeaderComponent blurred={blurred}>{children}</HeaderComponent>
  )
}

export const HeaderComponent = styled.header<{ blurred?: boolean; }>`
  position: fixed;
  width: 100%;
  min-height: 4rem;
  top: 0;
  background-color: var(--workspace-background);
  z-index: 3;

  ${props => props.blurred && css`
    filter: blur(0.5rem);
  `}
`