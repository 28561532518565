import React from 'react'
import styled, { CSSProperties } from 'styled-components'

export default function Notify({
  icon,
  title,
  message,
  buttons,
  style
}: {
    icon: React.ReactNode;
    title: React.ReactNode | string;
    message: React.ReactNode | string;
    buttons: React.ReactNode | Array<React.ReactNode>;
    style?: CSSProperties;
}) {

  return (
    <Container>
      <header>
        {icon}
      </header>
      <h1>{title}</h1>
      <p>{message}</p>
      <Buttons>
        {buttons}
      </Buttons>
    </Container>
  )
}


const Container = styled.div`
  position: relative;
  padding: 2.438rem 2.875rem 2.188rem 1.875rem;
  max-width: 32.625rem;

  border-radius: 0.5rem;
  background-color: var(--workspace-widget-background);

  header {
    color: var(--accent-red);
    svg {
      width: 1.875rem;
      height: 1.875rem;
      vertical-align: middle;
    }
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 1.313rem;
  }

  h1 + p {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    color: var(--text-secondary);
  }
`

const Buttons = styled.div`
  position: relative;
  margin-top: 1.125rem;
  margin-left: -0.750rem;

  & button:not(:nth-child(1)) {
    margin-left: 0.75rem;
  }
`