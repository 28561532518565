import React from 'react';
import styled from 'styled-components';

import { ReactMemo } from 'src/hooks';

export default ReactMemo(function OuterContainer({
  children
}: {
  children: React.ReactNode | Array<React.ReactNode>
}) {
  return (
    <Container className='outer-container'>{children}</Container>
  )
})

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 97.5rem;
  margin: 0 auto;
`