import React from 'react'
import PromtContainer from 'src/components/containers/PromptContainer'
import Steps from 'src/components/Steps'
import { Input, Button, InputContainer, show } from 'src/libs';
import { ReactComponent as KeyIcon } from 'src/static/icons/system/key.svg'

import api, { authToken } from 'src/services/api'
import store from 'src/store'
import { useTranslation } from 'src/i18n/useTranslation';


export default function SecurityForm({
  onLogin,
  onClose
}: {
    onLogin(): void
    onClose(): void
}) {
  const { t } = useTranslation();
  const [password, setPassword] = React.useState<string>('')

  return (
    <PromtContainer
      icon={<KeyIcon/>}
      title={t("chronos.profile.securityForm.makeSureitsYou.title")}
      subtitle={t("chronos.profile.securityForm.makeSureitsYou.subtitle")}
      content={
        <InputContainer label={t("chronos.profile.securityForm.makeSureitsYou.pusswordOfAccauntChronos")}>
          <Input 
            type="password" 
            size="big" 
            value={password} 
            readonly 
            autoFocus={true} 
            onFocus={(e: any) => e.target.removeAttribute('readonly')}
            onChange={setPassword} />
        </InputContainer>
      }
      footer={<Steps step={1} />}
      onClose={onClose}
      buttons={<Button disabled={!password} size="medium" color="accent" onClick={() => {
        api.reLogin(password)
          .then((res) => {
            if (res.jid) {
              authToken.setToken(res.jid);
              onLogin()
            }
          })
          .catch((res) => {
            switch (res.message) {
              case 'WRONG CHANNEL': {
                show({ text: t("chronos.profile.security.passwordConfirmForm.passwordChanged.error"), type: 'error', timeout: 5000 });
                break;
              }
              default: {
                show({ text: t("chronos.profile.showMessage.somethingWentWrongContactSupport"), type: 'error', timeout: 5000 });
                store.sendReport(res.message, res)
              }
            }
          })
      }}>{t("chronos.app.consultations.notification.9.submit")}</Button>}
    />
  )
}