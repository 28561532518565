import React from 'react';
import styled from 'styled-components';

import PromtContainer from 'src/components/containers/PromptContainer'
import SecurityForm from './SecurityForm'
import Steps from 'src/components/Steps'
import { Button, show } from 'src/libs';
import { ReactComponent as KeyIcon } from 'src/static/icons/system/key.svg'

import api from 'src/services/api'
import store from 'src/store'

import { isSecureSession, isOAuthUser } from 'src/utils'
import { useTranslation } from 'src/i18n/useTranslation';
import ReCAPTCHA from 'react-google-recaptcha';
import { isTester } from '../../helpers/autotests';


export default function PhoneConfirmhForm({
  onClose
}: {
  onClose(): void
}) {
  const [code, setCode] = React.useState<string>('')
  const [needLogin, setNeedLogin] = React.useState<boolean>(false)
  const [preloading, setPreloading] = React.useState<boolean>(false)
  const captchaRef = React.useRef<ReCAPTCHA>(null)

  const { t } = useTranslation();

  async function verifyPhoneNumber() {
    try {
      setPreloading(true)

      const token = await captchaRef.current?.executeAsync()

      if (!isTester(store.UserStore.email) && !token) {
        const message = 'Captcha error: ReCAPTCHA hasn\'t generated token. Probably user is bot.'
        throw new Error(message)
      }

      let phone = store.UserStore.mutablePhoneNumber
      phone = `${/^\+/.test(phone) ? `` : `+`}${phone}`

      await api.verifyChannel(phone, token!)
        .then((result) => {
          console.log('verify phone result -', result)
          store.isChannelVerified = true
          setPreloading(false)
        })
        .catch((result) => {
          setPreloading(false)
          store.setConfirmPhoneModalVisible(false)
          store.isChannelVerified = false

          let message = t("chronos.profile.security.phoneChanger.error.phoneNumberVerificationError");
          if (result?.message === 'WRONG CHANNEL') {
            message = t("chronos.profile.security.phoneChanger.error.wrongPhoneNumber");
          } else if (result?.message === 'exist' || result?.message === 'channel CHANNEL') {
            message = t("chronos.profile.security.phoneChanger.error.phoneAlreadyUsed");
          }
          else {
            result?.message && (message = result?.message);
          }

          show({ text: message, type: 'error', timeout: 5000 });
        })
    } catch(error: any) {
        console.error(error.message)
        setPreloading(false)
        store.setConfirmPhoneModalVisible(false)
        store.isChannelVerified = false
    }
  }

  React.useEffect(() => {
    if (!isSecureSession() && !isOAuthUser()) {
      setPreloading(false)
      setNeedLogin(true)
    } else if(!store.isChannelVerified) {
      verifyPhoneNumber()
    }
  }, [])

  return (
    <>
      { needLogin ?
        <SecurityForm onLogin={() => { setNeedLogin(false); verifyPhoneNumber() }} onClose={onClose}/>
        : <PromtContainer
          preloading={preloading}
          icon={<KeyIcon/>}
          title={t("chronos.profile.security.phoneConfirmForm.changePhoneNumber")}
          subtitle={t("chronos.profile.security.phoneConfirmForm.changePhoneNumber.subtitle", { number: store.UserStore.mutablePhoneNumber })}
          content={
            <CodeInput placeholder="****" value={code} onChange={(evt) => {
              setCode((evt.target as HTMLInputElement).value)
            }} />
          }
          buttons={<Button disabled={!code} size="medium" color="accent" onClick={() => {
            let phone = store.UserStore.mutablePhoneNumber
            phone = `${/^\+/.test(phone) ? `` : `+`}${phone}`
            api.changeChannnel(phone, code)
              .then(() => {
                store.setConfirmPhoneModalVisible(false);
                show({ text: t("chronos.profile.security.phoneConfirmForm.changePhoneNumber.show.success"), type: 'success', timeout: 5000 });
              })
              .catch(result => {
                let message = result.message;
                if (result.message === 'invalid token') {
                  message = t("chronos.profile.security.phoneConfirmForm.changePhoneNumber.show.error");
                }
                setPreloading(false);
                show({ text: message, type: 'error', timeout: 5000 });
              })
          }}>{t("chronos.profile.emailConfirmForm.sendCode")}</Button>}
          footer={<Steps step={2} />}
          onClose={onClose}
        />
      }
      {!isTester(store.UserStore.email) && <ReCAPTCHA
        sitekey="6LdqFGUnAAAAAFPjZAmWlI1FS7TQNxF6vIsf5JKR"
        size="invisible"
        ref={captchaRef}
      />}
    </>
  )
}

const CodeInput = styled.input`
  display: block;
  position: relative;
  width: 11.75rem;
  height: 3rem;
  padding-left: 1rem;
  border: 1px solid var(--element);
  border-radius: 5px;
  background-color: var(--input-background);
  color: var(--text-secondary);

  font-family: 'Apercu Pro', sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.25rem;
  letter-spacing: 0.187rem;

  &::placeholder {
    color: var(--text-secondary);
    transform: translateY(20%)
  }

  &:focus {
    outline: none;
  }
`
