import React from 'react';
import styled from 'styled-components';

import { Button, AppSwitcher } from 'src/libs';
import { ReactComponent as AppsIcon } from 'src/static/icons/system/apps.svg';
import { ReactComponent as ChronosLogoIcon } from 'src/static/icons/common/chronos-logo.svg';
import i18n from 'src/i18n/i18n';
import ToolTip from 'src/components/ui/ToolTip';

import { observer } from 'mobx-react';
import store from 'src/store';
import { useTranslation } from 'src/i18n/useTranslation';
import { device } from 'src/theme';

export default observer(function AppsControl() {
    const { appSwitchShow } = store
    function clickHandler(evt: Event) {
        evt.stopPropagation();
        store.appSwitchSetVisible(!appSwitchShow)
        store.tooltipSetVisible(false)
    }
    const { t } = useTranslation();
    return (
        <Container>
            <Button color="transparent" onClick={clickHandler}><AppsIcon width="1.5rem" height="1.5rem" /></Button>
            <ChronosLogoIcon width="1.75rem" height="1.75rem" />
            <figcaption>{t("chronos.profile.settings")}</figcaption>
            {
                store.appSwitchShow &&
                <AppSwitcher style={{ top: '4rem', left: '0.75rem', zIndex: '2' }}
                    user={{
                        avatarUrl: store.UserStore.avatarUrl,
                        name: store.UserStore.firstName,
                        levelOfAstrology: store.UserStore.levelOfAstrologyName!
                    }}
                    current={process.env.REACT_APP_PROFILE_APP_URL!}
                    onClose={() => { store.appSwitchSetVisible(false) }}
                    onLogOut={() => store.UserStore.logOut()}
                    lang={i18n.language}
                    t={t}
                />
            }
            {
                store.covered && store.tooltipShow &&
                <ToolTip
                    style={{ top: '4rem', left: '-0.25rem' }}
                    title={t("chronos.profile.appSwitcherItems.switchApps")}
                    content={t("chronos.profile.appSwitcherItems.switchApps.description")}
                    onClose={() => { store.tooltipSetVisible(false) }}
                />
            }
        </Container>
    )
})

const Container = styled.figure`
    display: flex;
    position: absolute;
    z-index: 100;
    top: 0;
    align-items: center;
    min-height: 4rem;
    color: var(--text-primary);
    cursor: pointer;

    & svg {
        vertical-align: middle;
    }

    & figcaption {
        display: inline-block;
        margin-left: 0.8rem;
        font-size: 1.125rem;
        line-height: 1;
        font-weight: 500;
        user-select: none;

        @media ${device.mobileL} {
            font-size: 1.125rem;
            font-weight: 400;
        }
    }
`