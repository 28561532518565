import React from 'react';
import styled from 'styled-components';

import { Button } from 'src/libs';

import { ReactComponent as FaqIcon } from 'src/static/icons/common/faq.svg';

export default function Faq({
    onClick
}: {
    onClick(): void
}) {
    return (
        <Container>
            <Button color="transparent" onClick={onClick}><FaqIcon width="1.5rem" height="1.5rem" /></Button>
        </Container>
    )
}

const Container = styled.figure`
    display: flex;
    position: absolute;
    right: 0.25rem;
    top: 0.5rem;
    align-items: center;
    color: var(--text-primary);
    opacity: 0.3;
    //pointer-events: none;

    & svg {
        vertical-align: middle;
    }
`