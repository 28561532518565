import React, { useLayoutEffect } from "react";

export let debugRender = false;

export function ReactMemo<P>(f: (p: P) => any) {  
  return React.memo((p: P) => {
    debugRender && console.log(f.name);
    return f(p);
  });
}

export function switcherHook(initial: boolean) {
  const [value, setValue] = React.useState(initial);
  
  return {
    value,
    on: React.useCallback(() => setValue(true), [value]),
    off: React.useCallback(() => setValue(false), [value]),
    switch: React.useCallback(() => setValue(!value), [value]),
  };
}

export const clickOutsideHook = (ref: any, cb: Function, deps: any[] = []) => {
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        cb();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, ...deps]);
};

export function setDocumentTitle(title: string) {
  useLayoutEffect(() => {
    document.title = title;
  }, []);
}
