import React from 'react'

import Notify from 'src/components/ui/wrappers/Notify'
import { Button, show } from 'src/libs'
import { ReactComponent as WarningIcon } from 'src/static/icons/system/warning.svg'

import api from 'src/services/api'
import store from 'src/store'
import { useTranslation } from 'src/i18n/useTranslation'

export default function AccountDeleteForm() {
  const { t } = useTranslation();
  return (
    <Notify 
      icon={<WarningIcon/>}
      title={t("chronos.profile.accountDeleting.title")}
      message={t("chronos.profile.accountDeleting.message")}
      buttons={
        <>
          <Button size="medium" color="transparent" onClick={() => store.setAccountDeletingModalVisible(false)}>{t("base.cancel")}</Button>
          <Button size="medium" color="var(--colors-red)" onClick={() => {
            api.deleteMe(encodeURIComponent(store.SecurityStore.finalReason))
              .then((res) => {
                store.setAccountDeletingModalVisible(false);
                store.UserStore.dropAccount();
              })
              .catch((e) => {
                show({ text: t("chronos.profile.accountDeleting.somethingWentWrong"), type: 'warning', timeout: 5000 });
                store.sendReport('delete account error -', e);
              })
          }}>{t("chronos.profile.accountDeleting.deletePermanently")}</Button>
        </>
      }
    />
  )
}
