import { lazy } from "react";
import { createBrowserHistory } from "history";
export const history = createBrowserHistory();

const PersonalPage = lazy(() => import('src/pages/Personal'));
const SecurityPage = lazy(() => import('src/pages/Security'));
const PaymentPage = lazy(() => import('src/pages/Payments'));
const NotificationPage = lazy(() => import('src/pages/Notifications'));
const PayoutPage = lazy(() => import('src/pages/Payouts'))

import { ReactComponent as PersonIcon } from 'src/static/icons/system/person.svg';
import { ReactComponent as PrivateIcon } from 'src/static/icons/system/private.svg';
import { ReactComponent as PaymentIcon } from 'src/static/icons/system/payment.svg';
import { ReactComponent as NotificationIcon } from 'src/static/icons/system/notification.svg';
import { ReactComponent as PayoutsIcon } from 'src/static/icons/system/payouts.svg';

export const routes = [
  { path: '/personal', Component: PersonalPage, title: "chronos.profile.title.personal", Icon: PersonIcon },
  { path: '/security', Component: SecurityPage, title: "chronos.profile.title.security", Icon: PrivateIcon },
  { path: '/payouts', Component: PayoutPage, title: "chronos.profile.title.payouts", Icon: PayoutsIcon, onlyForRu: true },
  { path: '/payments', Component: PaymentPage, title: "chronos.profile.title.payments", Icon: PaymentIcon },
  { path: '/notifications', Component: NotificationPage, title: "chronos.profile.title.notifications", Icon: NotificationIcon, disabled: true }
]