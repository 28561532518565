import { makeAutoObservable, runInAction } from "mobx";

import UserStore from './User';
import ConfigStore from './Config';
import SecurityStore from 'src/pages/Security/store';
import PayoutStore from "src/pages/Payouts/store";
import PaymentStore from 'src/pages/Payments/store';
import NotificationStore from 'src/pages/Notifications/store';

import { getDeviceDetect } from "src/utils";

class MainStore {
  [x: string]: any; 
   
  ConfigStore: ConfigStore = new ConfigStore()
  UserStore: UserStore = new UserStore()
  // PersonalStore: PersonalStore = new PersonalStore()
  SecurityStore: SecurityStore = new SecurityStore()
  PaymentStore: PaymentStore = new PaymentStore()
  PayoutStore: PayoutStore = new PayoutStore()
  NotificationStore: NotificationStore = new NotificationStore()

  appSwitchShow: boolean = false
  tooltipShow: boolean = false

  device: { isMobile: boolean; isTablet: boolean; isIPad: boolean } | null = null;
  
  confirmEmailModalVisible: boolean = false
  setConfirmEmailModalVisible(val: boolean) {
    // сбрасываем при закрытии модалки
    if (!val) {
      this.isChannelVerified = false
    }
    this.confirmEmailModalVisible = val
  }

  isChannelVerified: boolean = false
  confirmPhoneModalVisible: boolean = false
  setConfirmPhoneModalVisible(val: boolean) {
    // сбрасываем при закрытии модалки
    if (!val) {
      this.isChannelVerified = false
    }
    this.confirmPhoneModalVisible = val
  }

  confirmChangePasswordModalVisible: boolean = false
  setConfirmChangePasswordModalVisible(_: boolean) {
    this.confirmChangePasswordModalVisible = _
  } 
  
  accountDeletingModalVisible: boolean = false
  setAccountDeletingModalVisible(val: boolean) {
    this.accountDeletingModalVisible = val
  }

  closeSessionsModalVisible: boolean = false
  setCloseSessionsModalVisible(val: boolean) {
    this.closeSessionsModalVisible = val
  }


  // свойство блюрит контент. Сюда нужно добавлять переменные, отвечающие за показ модалок
  get covered() {
    return this.confirmPhoneModalVisible ||
      this.confirmEmailModalVisible ||
      this.confirmChangePasswordModalVisible ||
      this.accountDeletingModalVisible ||
      this.closeSessionsModalVisible
  }


  appSwitchSetVisible(value: boolean) {
    this.appSwitchShow = value
  }

  tooltipSetVisible(value: boolean) {
    this.tooltipShow= value
  }

  showAttachPhoneModal() {
    this.confirmPhoneModalVisible = true
  }


  get isLogin(): boolean {
    return Boolean(~this.UserStore.id)
  }

  pageLoading: boolean = false

  pageLoadingStart() {
    this.pageLoading = true
  }

  pageLoadingStop() {
    this.pageLoading = false
  }


  sendReport(message: string, v: any) {
    console.log('error: ', message)
  }

  constructor() {
    makeAutoObservable(this)

    this.device = getDeviceDetect();
  }
}

const store = new MainStore()
export default store

export { MainStore }