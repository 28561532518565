import React, { useState } from 'react';
import styled, { keyframes, css, StyledProps } from 'styled-components';
import { observer } from 'mobx-react';

import { device } from 'src/theme';

export default observer(function Modal({
  visible,
  children,
  onClose
}: {
  visible: boolean;
  onClose?(): void;
  children: React.ReactNode | React.ReactNodeArray;
}) {
  if (visible) {
    return (
      <Container>
        <Overlay onClick={onClose}/>
        <Content>
          {children}
        </Content>
      </Container>
    )
  } else {
    return null
  }
})

const Container = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
`
const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  /* backdrop-filter: brightness(0.9) blur(3px); */

  z-index: 1;
`

const modalFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const Content = styled.div`
  display: flex;
  position: relative;

  /* max-width: 32.500em; */
  /* max-height: 90%; */

  padding: 1em 0;
  z-index: 1;

  box-shadow: 0 0 24px rgba(0, 0, 0, 0.14);
  border-radius: 12px;

  animation: ${modalFadeIn} 0.2s ease-in-out;

  @media ${device.mobileL} {
    max-width: none;
    max-height: 100vh;
    width: 100vw;
    height: auto;
    border-radius: 0;
  }
`