export const isTester = (email: string) => {
  return [
		'chron.register.autotest101@rambler.ru',
		'chron.register.autotest102@rambler.ru',
		'chron.register.autotest103@rambler.ru',
		'chron.autotest101@rambler.ru',
		'chron.autotest102@rambler.ru',
		'chron.autotest103@rambler.ru',
		'chron.payments.autotest101@rambler.ru',
		'chron.payments.autotest102@rambler.ru',
		'chron.payments.autotest103@rambler.ru',
		'chron.ast.autotest101@rambler.ru',
		'chron.ast.autotest102@rambler.ru',
		'chron.ast.autotest103@rambler.ru',
		'chron.ast.adm.autotest101@rambler.ru',
		'chron.ast.adm.autotest102@rambler.ru',
		'chron.ast.adm.autotest103@rambler.ru'
  ].includes(email);
}
