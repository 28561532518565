import { makeAutoObservable, runInAction } from "mobx";
import api from 'src/services/api';

export default class SecurityStore {

  private _reasonsForDeletion: string[] = []

  set reasonsForDeletion(val: string[]) {
    this._reasonsForDeletion = val
    this._reasonsForDeletion.push("chronos.profile.security.reasonForDeletion.enotherWrite")
  }

  get reasonsForDeletion() {
    return this._reasonsForDeletion
  }

  private _activeReason: string = ''
  private _anotherReason: string = ''

  set activeReason(val: string) {
    this._activeReason = val
  }

  get activeReason() {
    return this._activeReason
  }

  set anotherReason(val: string) {
    this._anotherReason = val
  }

  get anotherReason() {
    return this._anotherReason
  }

  get finalReason() {
    return this.anotherReason || this.activeReason
  }

  async fetchSettingsConfig() {
    try {
      const config = await api.getReasonsForDeletion()
      if (config) {
        runInAction(() => {
          this.reasonsForDeletion = config.deleteReasons
          this.activeReason = config.deleteReasons[1]
        })
      }
    } catch (e) {
      console.log('fetch settings config -', e)
      throw e
    }
  }


  mailIsOpen: boolean = false
  phoneIsOpen: boolean = false
  passwordIsOpen: boolean = false

  mailToggle() {
    this.mailIsOpen = !this.mailIsOpen
  }
  phoneToggle() {
    this.phoneIsOpen = !this.phoneIsOpen
  }
  passwordToggle() {
    this.passwordIsOpen = !this.passwordIsOpen
  }

  constructor() {
    makeAutoObservable(this)
  }
}