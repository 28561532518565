import { makeAutoObservable, runInAction } from "mobx";
import { IWorkProfileCardView } from '@chronos/api';

export default class PayoutStore {

    private _cards: IWorkProfileCardView[] | null = [];
    
    constructor() {
        
        makeAutoObservable(this)

        // runInAction(() => {
        //     this._cards = [
        //         {
        //             cardId: '234543252',
        //             cardPan: '220220*****8517',
        //             domain: 0
        //         }
        //     ];
        // })
    }

    get cards(): IWorkProfileCardView[] | null {
        return this._cards;
    }

    set cards(items: IWorkProfileCardView[] | null) {
        runInAction(() => {
            this._cards = items;
        });
    }
}