import { makeAutoObservable, runInAction } from "mobx";
import ls from 'src/services/ls';
import { history } from 'src/router';
import { searchParams } from 'src/utils';

import { REACT_APP_PROCESSOR_APP_URL, REACT_APP_PROGNOSIS_APP_URL, REACT_APP_PORTAL_APP_URL, REACT_APP_INDI_APP_URL } from 'src/config';

export default class ConfigStore {
  _processorUrl: string = REACT_APP_PROCESSOR_APP_URL
  _dawnUrl: string = REACT_APP_PROGNOSIS_APP_URL
  _commonUrl: string = REACT_APP_PORTAL_APP_URL
  _indiUrl: string = REACT_APP_INDI_APP_URL

  private checkReferrer(ref: string) {
    if (/(app)(.+)?\.chronos/.test(ref)) {
      this._processorUrl = ref
      ls.set('processor', ref)
    } 
    else if (/(dawn)(.+)?\.chronos/.test(ref)) {
      this._dawnUrl = ref
      ls.set('dawn', ref)
    } else {
      this._commonUrl = ref
      ls.set('common', ref)
    }
  }

  handleReferrer() {
    if (location.search.includes('ref')) {
      const sp = searchParams();
      const referrer = sp.get('ref') || '';
      sp.delete('ref');
      const spString = sp.toString();
      history.replace(`${location.pathname}${spString ? `?${spString}` : ''}`);
      referrer && this.checkReferrer(referrer)
    }
  }


  get processorUrl() {
    return ls.get('processor') || this._processorUrl || ''
  }

  get dawnUrl() {
    return this._dawnUrl || ls.get('dawn') || ''
  }

  get indiUrl() {
    return this._indiUrl || ls.get('indi') || ''
  }

  get commonUrl() {
    return this._commonUrl || ls.get('common') || ''
  }

  constructor() {
    makeAutoObservable(this)
  }
} 